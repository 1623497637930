.logbook-head{
    color: #630A37;
    background-color: #FAFAFA;
}
.logbook-head__title{
    font-weight: bold;
    font-size: 2em;
}
.logbook__left-col{
    overflow-x: hidden;
    transition: 0.5s max-width;
    will-change: max-width;
    transform: translate(0px);
    min-height: calc(100vh - 16.9em);
}
.logbook__left-col.collapsed{
    max-width: 6.9em !important;
}
.logbook-filter__title{
    overflow-x: hidden;
    transition: 0.5s;
    position: relative;
    font-size: 1.6em;
    font-weight: bold;
    color: #915372;
}
.logbook-filter__title::after{
    position: absolute;
    display: block;
    content: ' ';
    pointer-events: none;
    width: 100%;
    height: 100%; 
    left: 0;
    top: 0;
}
.logbook__left-col.collapsed .logbook-filter__title{
    max-width: 0;
}
.logbook-filter__collapse-btn{
    height: 2em !important;
    width: 2em !important;
}
.logbook-filter__collapse-ic{
    transform: rotate(0);
}
.logbook__left-col.collapsed .logbook-filter__collapse-ic{
    transform: rotate(180deg);
}
.logbook-filter__filter-inputs-field{
    border-top: 1px solid #919191;
    margin-top: 1em;
    padding-top: 0.8em;
}
.logbook-filter__filter-inputs-field .range-col{
    width: 50%;
    padding-left: 0.5em !important;
}
.logbook-filter__filter-inputs-field .range-col:first-child{
    padding-left: 0  !important;
    padding-right: 0.5em  !important;
}
@media (min-width : 237px){
    .logbook-filter__filter-inputs-field .range-col{
        padding: 0 !important;
        width: 100%;
    }
}
.logbook-filter__type-title {
    font-size: 1.6em;
    font-weight: bold;
    color: #915372;
}
.logbook-flter__type-input-bg{
    height: 27px !important;
    width: 44px !important;
    border-radius: 1em !important;
    margin-top: -14px !important;
    margin-left: -19px !important;
}
.logbook-flter__type-input-base{
    margin-left: -6px !important;
}
.logbook-filter__type-input-ic{
    width: 25px !important;
    height: 25px !important;
}
.logbook-flter__type-input-bg.checked{
    background-color: var(--logbook-input-color) !important;
}
.logbook-filter__type-input-checked-ic{
    background-color: var(--logbook-input-color) !important;
    margin-right: -10px;
}