.attachment-upload-field{
    border: 2px dashed #E1DFDC;
    border-radius: 3px;
    padding: 2em 1em;
    min-width: 150px;
}
.attachment-upload-btn {
    color: #79C1DB !important;
    border-color: #79C1DB !important;
}
.attachment-upload-btn:hover {
    background-color: #F1F5FB !important;
}

@media (min-width: 1300px){
    #drop-area {
        flex: 0 0 33.333%;
        min-width: 3.3333% !important;
        
    }
}
@media (max-width: 1299px){
    #drop-area {
        flex: 0 0 100%;
        min-width: 100% !important;
        
    }
}