/*################################## Assembleia #################### */
.assembleia-card-container{
    height: 58px;
}
.assembleia-container{
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 3rem;
    background-color:#FAFAFA;
}

.anexar-investidores-assembleia_header{
    color: #8c8279;
    font-weight: 700;
    /* display: grid; 
    grid-template-columns: 10% 50% 40%;
    flex-flow: nowrap; */
    align-items: center;
    /*font-size: .8em;*/
}
/************ Table **************/
.pad-t-1{
    padding-top: 1em;
}
.assembleia-investidor-table{
   
    font-size: 14px;
}
.assembleia-investidor-table .header{
    display: flex !important;
    padding: 1em;
    background-color: #915372;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
   
}
.assembleia-investidor-table .table_row{
    margin-top : .32rem;
    margin-bottom: .32rem;
    border-radius: 0.5em;
    border-left: 6px solid #ccc;
    box-shadow: 0px 2px 4px -2px #000;
    
    display: flex !important;
    
}
.table_row> div{
    padding: 1em;
    font-weight: 300;
    word-break: break-word;
}
.table_row:hover{
    border-left: 6px solid #8c8279;
    box-shadow: 0px 3px 20px -3px #8c8279;
}
.table-ordens-assembleia{
    display:grid !important;
    grid-template-columns: 30% 40% 20% 10% !important;
}

/********************** Divs ***************************/
.assembleia-investidor-header{
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6% 16.6% !important;
}
.assembleia_title{
    word-break: break-all;
    max-width: 100px;
}
.dropdown-title{
    color: #8c8279;
    font-weight: 700;
}



.assembleia-investidor-table .emtpy-row{
    color: #8c8279;
    text-align: center;
    padding: 1em;
    font-weight: 700;
}




.card-investidores-header{
    padding-bottom: 1em;
    display:grid !important;
    grid-template-columns: 95% 5% !important;
}

.card-cedoc-header{
    padding-bottom: 1em;
}

.card-spreadsheet-model{

    font-weight: 350;
    font-size: 13px;
    padding: 5px 16px;
    background-color: #F4F3F2;
    display: grid;
    /* grid-template-columns: 50% 50%; */
    flex-flow: wrap;
    max-width: 285px;
    min-width: 285px;
    min-height: 100px;
    /*
    font-weight: 350;
    font-size: 13px;
    padding: 5px 16px;
    background-color: #F4F3F2;
    max-width: 455px;
    min-width: 455px;
    max-height: 1000px;
    display: flex;
    flex-wrap: wrap;
    */
    
}
.card-spreadsheet-model_container{
    margin: 5px 16px;
}
.card-spreadsheet-model_button-ic{
    color: #FFF;
    font-size: 1.5em;
    padding-left: 10px;
}
.button-donwload-model{
    background-color: #79C1DB !important;
    color: #FFF !important;
    max-width: 186px !important;
    min-width: 186px !important;
 }

/******** File ******************/
.assembleia-file-choose-btn{
    max-height: 36px !important;
    min-height: 36px !important;
    color: #79C1DB !important;
    border-color: #79C1DB !important;
}

/******* card ******************/
.assembleia_card-attachment{
    max-width: 250px;
    border-radius: 5em;
    background-color: #8C8279;
    color:#FFFF;
}
.assembleia_card-attachment .label{
    padding-left: 16px;
    background-color: #8C8279 !important;
}
.assemlbleia__card-attachment-container {
    font-size: 14px;
    color: #FFF;
    font-weight: bold;
    padding: 0.5em 0.5em 0.5em 0.5em;
}

.logbook-modal button:focus{
    outline: none;
}
.file-card-attachment-ic{
    color: rgb(151, 215, 0);
    font-size: 1.5em;
    padding-left: 10px;
}
.logbook__card-attachment button:focus{
    outline: none;
}

.logbook-modal__title {
    font-weight: bold;
    color: #630A37 !important;
}
