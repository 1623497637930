.expansion-pane{
    margin: 1em 0em;
    padding: 10px;
    border-radius: .5em;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
}
.expansion-pane .header{
/*     
    display: grid;
    grid-template-columns: 96% 4%; */
    display: flex;
    margin-bottom: 1em;
}
.expansion-pane .content{
    transition: max-height 5s ease-in-out;
    transition-duration: 402ms;
    transition: display 5s ease-in-out;
    max-height: 0;
    overflow: unset;
    display: none;
}
.active-pane{
    display:flex !important;
    max-height: 300vh !important;
 
}
.drop_button{
    outline: none !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
    align-self: center;
    transition: .25s ease-in-out;
    
}
.button_active{
    outline: none;
    transform: rotate(180deg);
    transition: .25s ease-in-out;
   
}
.drop_icon{
    align-self: center;
    cursor: pointer;
}
