.logbook__card-field{
    padding: .5em !important;
}
.logbook__card-body{
    background-color: #ffffff;
    border-radius: 1em 0 1em 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important
    
}
.logbook__card-body-header{
    border-bottom: 1px solid #918f8d2d;
    font-weight: bold;
    color: #8c8279;
    overflow:hidden;
    
    
    
}
.logbook__card-body-header-title{
    /* font-size: 1.2em; */
    max-width: 20em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media(min-width:205px){
	.logbook__card-header-contents{
		width:50%;
	}
}

.logbook__card-body-header-secondary-info{
    font-size: .9em;
}

.logbook__card-body-header-Tertiary-info{
    font-size: .6em;
}


.logbook__card-body-header-thirht-info{
    font-size: .9em;
}

.logbook__card-body-content{
    min-height: 21vh;
    max-height: 21vh;
    overflow: auto;
}
.logbook__card-body-content.hide{
    /* display:none; */
    
}
.logbook__card-body-footer{
    height: 3em;
}
.logbook__card-body-footer-spacers{
    border-right: 1px solid #fffdfd46 !important;
}