.line-place {
  position: static;
  overflow: hidden;
  background-color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.15) 15%,
    rgba(0, 0, 0, 0.08) 30%
  );
  background-size: 1200px 100%;
  width: 100%;
  height: 10px;
  margin: 8px 0px;
  animation: placeholderShimmer 2s linear infinite;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.line-place.sm {
  height: 10px;
}
.line-place.md {
  height: 25px;
}
.line-place.lg {
  height: 55px;
}
.line-place.xlg {
  height: 80px;
}
.line-place.full {
  height: 100%;
}