.togglediv.red{
    background-color: #eca5a1 !important;
}
.togglediv.grey{
    background-color: #ddd !important;
}
.togglediv.green{
    background-color: #17D49877 !important;
}

.togglediv{
    overflow: hidden;
    border-radius: 20px;
    width: 7em;
    height: 2em;
    position: relative;
    display: flex;
}
.toggle_icon.fa-times.grey,.toggle_icon.fa-check.grey{
    color: white !important;
}

.toggle_icon.fa-times{
    color: #d14842;
}
.toggle_icon.fa-check{
    color: #17D498;
}

.togglediv__item-higlited{
    background-color: black;
    box-shadow: 0px 8px 24px #00000029;
}

.togglediv__item-higlited >.toggle_icon{
    color:white !important;
}
.togglediv__item-higlited.red{
    background-color: #d14842 !important ;
}
.togglediv__item-higlited.grey{
    background-color:white !important;
}
.togglediv__item-higlited.green{
    background-color: #17D498 !important;
}


.togglediv__item{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 2em;
    border-radius: 5em;
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.togglediv__item:first-child, .togglediv__item:last-child{
    margin: unset !important;
}
