.icon-menu, .menu-collapse-icon{
    font-size: 1.4em;
}
.menu-collapse-icon{
    font-size: 1em;
    line-height: 0.9em;
    transition: 0.3s transform;
    transform-origin: center;
}
.menu-collapse-icon.visible{
    transform: rotateZ(180deg);
}
.menu-sub-items{
    background-color: rgba(255, 255, 255, 0.1);
}
.menu-item-label{
    white-space: normal;
}