.logbook-register-events {
    min-width: 70vw;
}
.logbook-register-events__submit-btn{
    background-color: #79C1DB !important;
    color: #630A37 !important;
}
.logbook-register-events__submit-btn:hover{
    background-color: #65B3D0 !important;
}
.logbook-register-events__operation-field {
    margin: 16.2px 0 0 0;
}
.logbook-register-events__operation-field.disabled {
    margin-top: 1.8em;
}
