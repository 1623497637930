/* ---------------------------------------------------
    Field floating
----------------------------------------------------- */
.field-floating {
  margin-bottom: 15px;
  padding-bottom: 0;
  
}
.field-floating .form-control {
  margin-bottom: 0;
  height: 38px;
}
.field-floating label,
.field-floating label.control-label,
.has-float-label label,
.has-float-label label.control-label {
  margin: 0;
  position: absolute;
  top: 11px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.field-floating .dropdown-list-select {
  padding: 0;
}
.field-floating .rw-input {
  font-weight: 400;
}
.field-floating  .rw-dropdown-list-input {
    padding-left: 0;
}
.field-floating .dropdown-list-select + label,
.field-floating .dropdown-list-select + label.control-label {
  top: -14px;
}
.field-floating .rw-widget-input, .field-floating .rw-combobox > .rw-widget-picker {
  height: 38px;
}
.field-floating .rw-widget-input, 
.field-floating .rw-combobox > .rw-widget-picker, 
.field-floating .rw-combobox .rw-select, 
.field-floating .rw-state-focus>.rw-widget-input, 
.field-floating .rw-state-focus>.rw-widget-input:hover, 
.field-floating .rw-combobox.rw-state-focus>.rw-widget-picker, 
.field-floating .rw-combobox.rw-state-focus>.rw-widget-picker:hover {
    border:none;
    box-shadow: none;
}
.field-floating .rw-combobox .rw-input {
    padding: .7px 0;
}

.field-floating input:not([value=""]) + label,
.field-floating textarea:not(:empty) + label,
.has-float-label .form-control + label,
.field-floating input[type="date"] + label,
.field-floating input[type="date"] + label.control-label,
.field-floating input[type="month"] + label,
.field-floating input[type="month"] + label.control-label{
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: -1.2em;
  left: 0;
  /* z-index: 3; */
  line-height: 1;
  padding: 0 1px;
}
.form-control:focus {
    outline: 0;
    border:none;
    box-shadow: none;
}
.field-floating input:focus::-webkit-input-placeholder, .field-floating textarea:focus::-webkit-input-placeholder {
    opacity: 1;
}
.field-floating input:focus::-moz-placeholder, .field-floating textarea:focus::-moz-placeholder {
    opacity: 1;
}
.field-floating input:focus:-ms-input-placeholder, .field-floating textarea:focus:-ms-input-placeholder {
    opacity: 1;
}
.field-floating input:focus::-ms-input-placeholder, .field-floating textarea:focus::-ms-input-placeholder {
    opacity: 1;
}
.field-floating input::-webkit-input-placeholder, .field-floating textarea::-webkit-input-placeholder {
    opacity: 0;
}
.field-floating input::-moz-placeholder, .field-floating textarea::-webkit-input-placeholder {
    opacity: 0;
}
.field-floating input:-ms-input-placeholder, .field-floating textarea:-ms-input-placeholder {
    opacity: 0;
}
.field-floating input::-ms-input-placeholder, .field-floating textarea::-ms-input-placeholder {
    opacity: 0;
}
/* ---------------------------------------------------
    FORM CONTROL
----------------------------------------------------- */
input.form-control[disabled], input.form-control[disabled] + label {
    color: rgba(0, 0, 0, 0.38);
}
/* .field-floating.has-float-label:not(.form-list-select):after, .field-floating.has-float-label .rw-state-focus:after, .field-floating.has-float-label .rw-combobox {
    transform: scaleX(1)
} */
.field-floating:not(.form-list-select):after, .field-floating .rw-dropdown-list:after, .field-floating .rw-combobox:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid rgb(98, 91, 84);
    pointer-events: none;
}
.field-floating.has-error:not(.form-list-select):after, .field-floating.has-error .rw-dropdown-list:after, .field-floating.has-error .rw-combobox:after {
    border-bottom-color: #f44336;
    z-index: 100;
}
.field-floating:not(.form-list-select):before, .field-floating .rw-dropdown-list:before, .field-floating .rw-combobox:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
}
.has-float-label:hover:not(.form-list-select):before, .field-floating:hover:not(.form-list-select):before, 
.has-float-label:hover .rw-dropdown-list:before, .field-floating:hover .rw-dropdown-list:before,
.has-float-label:hover .rw-combobox:before, .field-floating:hover .rw-combobox:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}
.field-floating.has-error:not(.form-list-select):before, .field-floating.has-error .rw-dropdown-list:before, .field-floating.has-error .rw-combobox:before {
    border-bottom-color: #f44336;
}
.filter-input {
    background-color: transparent;
}
.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}