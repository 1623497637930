.pure-checkbox *,
.pure-radiobutton * {
  box-sizing: border-box;
}
.pure-checkbox *:before,
.pure-radiobutton *:before,
.pure-checkbox *:after,
.pure-radiobutton *:after {
  box-sizing: border-box;
}
.pure-checkbox input[type="checkbox"],
.pure-radiobutton input[type="checkbox"],
.pure-checkbox input[type="radio"],
.pure-radiobutton input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.pure-checkbox input[type="checkbox"]:focus + label:before,
.pure-radiobutton input[type="checkbox"]:focus + label:before,
.pure-checkbox input[type="radio"]:focus + label:before,
.pure-radiobutton input[type="radio"]:focus + label:before,
.pure-checkbox input[type="checkbox"]:hover + label:before,
.pure-radiobutton input[type="checkbox"]:hover + label:before,
.pure-checkbox input[type="radio"]:hover + label:before,
.pure-radiobutton input[type="radio"]:hover + label:before {
  border-color: #000;
  background-color: #f2f2f2;
}
.pure-checkbox input[type="checkbox"]:active + label:before,
.pure-radiobutton input[type="checkbox"]:active + label:before,
.pure-checkbox input[type="radio"]:active + label:before,
.pure-radiobutton input[type="radio"]:active + label:before {
  transition-duration: 0s;
}
.pure-checkbox input[type="checkbox"] + label,
.pure-radiobutton input[type="checkbox"] + label,
.pure-checkbox input[type="radio"] + label,
.pure-radiobutton input[type="radio"] + label {
  position: relative;
  padding: 5px 5px 5px 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.pure-checkbox input[type="checkbox"] + label:before,
.pure-radiobutton input[type="checkbox"] + label:before,
.pure-checkbox input[type="radio"] + label:before,
.pure-radiobutton input[type="radio"] + label:before {
  box-sizing: content-box;
  content: '';
  color: #CCC;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #000;
  text-align: center;
  transition: all 0.4s ease;
}
.pure-checkbox input[type="checkbox"] + label:after,
.pure-radiobutton input[type="checkbox"] + label:after,
.pure-checkbox input[type="radio"] + label:after,
.pure-radiobutton input[type="radio"] + label:after {
  box-sizing: content-box;
  content: '';
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}
.pure-checkbox input[type="checkbox"]:disabled + label:before,
.pure-radiobutton input[type="checkbox"]:disabled + label:before,
.pure-checkbox input[type="radio"]:disabled + label:before,
.pure-radiobutton input[type="radio"]:disabled + label:before {
  border-color: #999999;
}
.pure-checkbox input[type="checkbox"]:disabled:focus + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:focus + label:before,
.pure-checkbox input[type="radio"]:disabled:focus + label:before,
.pure-radiobutton input[type="radio"]:disabled:focus + label:before,
.pure-checkbox input[type="checkbox"]:disabled:hover + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:hover + label:before,
.pure-checkbox input[type="radio"]:disabled:hover + label:before,
.pure-radiobutton input[type="radio"]:disabled:hover + label:before {
  background-color: inherit;
}
.pure-checkbox input[type="checkbox"]:disabled:checked + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:checked + label:before,
.pure-checkbox input[type="radio"]:disabled:checked + label:before,
.pure-radiobutton input[type="radio"]:disabled:checked + label:before {
  background-color: #999999;
}
.pure-checkbox input[type="checkbox"] + label:after,
.pure-radiobutton input[type="checkbox"] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #FFF;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
.pure-checkbox input[type="checkbox"]:checked + label:after,
.pure-radiobutton input[type="checkbox"]:checked + label:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.pure-checkbox input[type="radio"]:checked + label:before,
.pure-radiobutton input[type="radio"]:checked + label:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}
.pure-checkbox input[type="radio"]:checked + label:after,
.pure-radiobutton input[type="radio"]:checked + label:after {
  transform: scale(1);
}
.pure-checkbox input[type="radio"] + label:before,
.pure-radiobutton input[type="radio"] + label:before,
.pure-checkbox input[type="radio"] + label:after,
.pure-radiobutton input[type="radio"] + label:after {
  border-radius: 50%;
}
.pure-checkbox input[type="checkbox"]:checked + label:before,
.pure-radiobutton input[type="checkbox"]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: #000;
}
.pure-checkbox input[type="checkbox"]:checked + label:after,
.pure-radiobutton input[type="checkbox"]:checked + label:after {
  transform: rotate(-45deg) scale(1);
}
.pure-checkbox.pure-checkbox-inline,
.pure-radiobutton.pure-checkbox-inline,
.pure-checkbox.pure-radiobutton-inline,
.pure-radiobutton.pure-radiobutton-inline {
  display: inline-block;
}
.pure-checkbox-toggle input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.pure-checkbox-toggle input[type="checkbox"] + label {
  position: relative;
  padding: 5px 5px 5px 40px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  display: block;
}
.pure-checkbox-toggle input[type="checkbox"] + label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  content: '';
  color: #000;
  width: 35px;
  height: 20px;
  border-radius: 50px;
  text-align: center;
  transition: all 0.4s;
  background-color: #000;
}
.pure-checkbox-toggle input[type="checkbox"] + label:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  left: 0;
  content: '';
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  transition: all 0.2s;
  box-sizing: border-box;
}
.pure-checkbox-toggle input[type="checkbox"]:checked + label:before {
  box-shadow: inset 0 0 0 10px #000;
}
.pure-checkbox-toggle input[type="checkbox"]:checked + label:after {
  left: 15px;
  border-color: #000;
  background-color: #ffffff;
}
.pure-checkbox-toggle input[type="checkbox"]:disabled + label {
  cursor: default;
}
.pure-checkbox-toggle input[type="checkbox"]:disabled + label:before {
  box-shadow: inset 0 0 0 10px #e3e3e3;
}
.pure-checkbox-toggle input[type="checkbox"]:disabled + label:after {
  border-color: #e3e3e3;
}
.pure-checkbox-toggle input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: inset 0 0 0 10px #999999;
}
.pure-checkbox-toggle input[type="checkbox"]:disabled:checked + label:after {
  border-color: #999999;
}
.pure-checkbox-toggle--right input[type="checkbox"] + label {
  padding: 5px 40px 5px 5px;
}
.pure-checkbox-toggle--right input[type="checkbox"] + label:before {
  left: auto;
  right: 0;
}
.pure-checkbox-toggle--right input[type="checkbox"] + label:after {
  left: auto;
  right: 15px;
}
.pure-checkbox-toggle--right input[type="checkbox"]:checked + label:after {
  left: auto;
  right: 0;
}
@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #000;
  }
}
body {
  color: #000;
  background: #f4f4f4;
  font-family: 'Raleway', sans-serif;
}
.page {
  margin: 0 auto;
  padding: 20px 30px;
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0 0 4px #000;
}
.page h1.page-title {
  font-weight: bold;
  color: #000;
  margin: 20px 0 40px;
  padding: 0;
  text-align: center;
}
ul.input-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.input-list li {
  margin: 10px 0 ;
  padding: 0;
}
.panel {
  margin: 20px 0;
}
.panel .panel-heading {
  background: #000;
  color: #ffffff;
  padding: 20px 30px;
}
.panel .panel-body {
  padding: 20px;
  border: 1px solid #000;
}
