.card-attachment {
    min-width: 270px;
}
.card-attachment-ic{
    color: #630A37;
    font-size: 2.1em;
}
.download-button {
    color: #79C1DB;
    font-size: 2.1em;
}
.download-button:hover {
    color: #65B3D0;
}
/* .delete-button {
    color: #EC3B3D;
} */
button:focus{
    outline: none;
}
.card-attachment-container {
    font-size: 1.1em;
    color: #8C8279;
    font-weight: bold;
    padding: 0.5em 0.7em 0.5em 0.7em;
    text-align: left;
    width: 270px;
}
.logbook-modal__title{
    font-weight: bold;
    color: #8C8279 !important;
}