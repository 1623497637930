.logbook__timeline-item{
    margin-top: 1em;
}
.logbook__timeline-item-header{
    cursor: pointer;
}
.logbook__timeline-btn {
    height: 1.5em;
    width: 1.5em;
    padding: 0 !important;
    border-radius: 2em !important;
    border: 0.2em solid #630A37 !important;
    color: #630A37 !important;
    font-weight: bold !important;
}
.logbook__timeline-btn-title {
    font-size: 1.4em !important;
    color: #630A37 !important;
    font-weight: bolder;
    padding-left: .9em;
}
.logbook__timeline-btn-horizontal-line{
    width: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    border: .5px solid #918f8d11;
}
.logbook__timeline-btn:hover{
    background-color:transparent !important;
}
.logbook__timeline-item-greenline{
    width: 2.7em;
    display: flex;
    justify-content: center;
    margin-bottom: -1.5em;
}
.logbook__timeline-item-greenline > div {
    width: 0.4em; 
    background-color: #630A37;
    border-radius: 2em;
    margin-top: -0.3em;
}
.logbook__timeline-item:last-child .logbook__timeline-item-greenline{
    transition: 0.5s;
    transition-property: opacity, transform;
    opacity: 1;
    transform-origin: top center;
    transform: scaleY(1);
}
.logbook__timeline-item:last-child .logbook__timeline-item-greenline.hide{
    opacity: 0;
    transform: scaleY(0);
}
.logbook__timeline-cards-area{
    background-color: transparent;
}
.logbook__timeline-cards-area.hide{
    background-color: transparent;
    border-radius: 0.5em;
}